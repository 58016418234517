const i18n = {
    'en': {
        common: {
            toolName: `Quick Risk Estimation (QRE) Tool`,
            forEnterprises: 'For Micro, Small and Medium-sized Enterprises (MSMEs)',
            audioAssistance: 'Audio assistance for visually impaired respondent',
            chooseLanguage: 'Select language',
            logout: 'Logout'
        },
        dashboard: {
            toolInfo: `This tool is intended to help micro, small and medium-sized enterprises, especially in the informal sector, to develop a basic understanding of their level of risk and is meant to be one tool among many to help enterprise/business owners understand their risk levels & build their resilience.`,
            aboutTool: 'About the Tool',
            takeMeSurvey: 'Take me to the Tool',
            inParnership: 'In parnership with'
        },
        aboutTool: {
            header: 'Quick Risk Estimation Tool for Micro, Small and Medium-sized Enterprises (MSMEs)',
            introduction: 'Introduction',
            introLine1: `The COVID-19 pandemic has resulted in severe economic losses calling for a sustained period of
                interventions from both governments and the private sector to respond and recover. Micro,
                Small and Medium Enterprises (MSMEs) on one hand are at a high risk of being of severely
                impacted by the broader effects of the outbreak and on the other also the ones who are
                leading the fight to survival not only for their own business but also for the overall livelihood
                and well-being of their community.`,
            introLine2: `Among the host of challenges faced by MSME, the most notable is a lack of access to credit.
                For example, in Southeast Asia 33% of MSMEs lack access to loans and a line of credit1 and are
                thus more vulnerable to shocks compared to larger enterprises. In the event of bigger shocks,
                like COVID-19 the MSMEs face an existential risk due to their smaller set of – both financial and
                non-financial – resources.`,
            introLine3: `Most MSMEs do not have enough in-house technical expertise, nor access to expertise in their
                area to help them develop a business continuity and recovery plans. In order to support MSMEs
                in aligning their thinking towards risk proofing their businesses, the proposed quick self-risk
                estimation tool aims to:`,
            scopeofTool: 'Scope of the tool',
            scopeLine1: `To support business owners in identifying possible internal and external risks to their
            business from COVID-19.`,
            scopeLine2: `To provide a general estimation of individual business risk (MSMEs) based on potential
            Impact (direct) and likelihood (possible indirect and wider impacts) of COVID-19.`,
            scopeLine3: `Strengthen risk awareness and communication between business owners.`,
            limitationofTool: 'Limitation of the tool',
            limitationLine1: `The tool does not undertake a comprehensive risk assessment of the MSME business, which requires more substantial analysis. Instead, this tool aims to provide a a quick general estimation of the current situation in which a business in operating. A business owner may use this tool to estimate the temporal change in the conditions and how that impacts the overall business risk. Based on the general estimation, the UNDRR COVID-19 Small Business Continuity and Recovery Planning Toolkit can then be used to look at specific risk management actions.`,
            targetGroup: 'Target Group',
            targetGroupLine1: `Business owners belonging to Micro Small and Medium Scale Enterprises (MSMEs) in Asia Pacific region.`,
            platform: 'Platform',
            platformLine1: `Mobile, Tablet and Computer`,
            framework: 'Framework and Indicators',
            frameworkLine1: `In the COVID-19 pandemic, a business would experience two broad risks. Risks due to both
                internal factors and external factors as shown in figure 1. There are 4 factors integral to a business
                namely: a) Human Resource, b) operation; c) finance and Technology & d) innovation. These
                factors are managed by the business and necessary changes be made to them based on the
                changing scenario. While in the external risk, there are three factors that influence the outcome
                of a business namely: a) Government policies; b) Change in Market and c) other natural hazards.
                The external risks are often beyond the control of an individual businesses to manage.`,
            frameworkLine2:'Figure 1. A Conceptual Framework for Business Risk in COVID-19 Pandemic',
            tableHead1:'Human Resource',
            tableHead2:'Operations',
            tableHead3:'Finance',
            tableHead4:'Technology and Innovation',
            row11:'Employee Availability',
            row12:'Supply chain ',
            row13:'Existing liabilities',
            row14:'Cyber Security',
            row21:'Employee Behaviour',
            row22:'Public utilities',
            row23:'Financial capacity',
            row24:'Innovation',
            row31:'Type of employment',
            row32:'Workplace safety',
            row33:'Insurance',
            row34:'Access to digital markets',
            row41:'Impact on Employees',
            row42:'Physical assets',
            references:'References',
            references1:`COSO (Committee of Sponsoring Organizations of the Treadway Commission), (2004). Enterprise Risk
                Management - Integrated Framework,Vol. 2.`,
            references2:`Ekwere N. (2016) FRAMEWORK OF EFFECTIVE RISK MANAGEMENT IN SMALL AND MEDIUM
                ENTERPRISES (SMEs): A LITERATURE REVIEW; Volume 20 Nov 1, 2016.`,    
            references3:`ILO (2020) The six-step COVID-19 business continuity plan for SMEs.`,    
            references4:`ISO 22301 Societal security: Business continuity management systems.`,    
            references5a:`KPMG (2020) Covid-19 Response Assessment Tool accessed online at`,    
            references5b:`https://aspac.kpmgcovidresponse.com/`,    
            references5c:`on 18.05.2020.`,    
            references6:`McGuinness W (2006). Managing the business risk of a pandemic: Lessons from the past and a
                checklist for the future, Discussion paper, Sustainable Future.`,    
            references7:`UAE (2020) Business Continuity Readiness Guidelines for UAE Organizations in the in the event of the
                Novel Coronavirus (COVID- 1 9), National Emergency Crisis and Disasters Management Authority
                (NECMA), March 2020.`,    
            references8:`UNDRR (2013) Global Assessment Report on Disaster Risk Reduction.`,    
            references9:`UNDRR (2020) COVID-19 Small Business Continuity and Recovery Planning Toolkit.`,    
            references10a:`UNESCAP (nd) SMEs IN ASIA AND THE PACIFIC, Accessed on line at`,    
            references10b:`https://www.unescap.org/sites/default/files/7%20-%201.%20SMEs%20IN%20ASIA`,    
            references10c:`%20AND%20THE%20PACIFIC.pdf`,    
            references11:`World Health Organisation (2018) A checklist for pandemic influenza risk and impact management.`,    
        },
        login: {
            login: `Login`,
            errormsg: 'That is the wrong email or password ',
            erroremail: 'Enter valid email address',
            password: 'Enter password',
            invalidpassword: 'Invalid password should be at least 8 character.',
            forgotpassword: 'Forgot Password?',
            loginWith:'Login with',
            signup:'Sign up',
            dontaccount:`Don't have an account?`,
            guest:'Guest Login',
            email:'Enter email address',
            enterPassword:'Enter password',
            guestLogin:'Guest Login',
            guestLoginData:'If you continue as a guest, your results will not be saved and you will not be able to track your risk in the future. Do you want to proceed?',
            cancel:'Cancel',
            Proceed:'Proceed',
            guestLogin:'Guest Login',
        },
        forgotPassword:{
            forgotPassword:'Forgot Password',
            error:'No user with this email address.',
            email:'Enter email address',
            invalidemail:'Enter valid email address',
            submit:'Submit'
        },
        changePassword:{
            changePassword:'Change Password',
            error:'No user with this email address.',
            email:'Enter email address',
            invalidemail:'Enter valid email address',
            submit:'Submit'
        },
        register:{
            register:'Register',
            error:'Failed to registered user, please try again.',
            firstName:'Enter first name',
            firstNameLable:'First name',
            firstNameErr:'Please Enter first name',
            lastNameLable:'Last name',
            lastName:'Enter last name',
            lastNameErr:'Please Enter last name',
            emailLable:'Email',
            email:'Enter email address',
            emailErr:'Please Enter valid email address',
            passwordLable:'Password',
            password:'Enter password',
            passwordErr:'Please Enter valid password',
            invalidpasswordErr:'Invalid password should be at least 8 character.',
            signup:'Sign up',
            haveaccount:'Already have an account? ',
            signin:'Sign in',
        },
        questionnaire:{
            genaralStatement:'General Statement:',
            genaralStatement1:`This survey is designed to help you conduct a quick estimation of your business’s risk in the context of COVID-19 pandemic.`,
            genaralStatement2:`To enable an accurate display of your risk map, please allow location sharing. In the dashboard, "Risk around you" is shown based on your present location.`,
            genaralStatement3:` This survey may take 10-15 minutes to complete.`,
            genaralStatement4a:`For terminologies please refer `,
            genaralStatement4b:`https://www.undrr.org/terminology`,
            disclaimer:'Disclaimer:',
            disclaimer1:`Please note that the outcome of the tool is an indicative risk arising due to the current
                pandemic. The tool is not intended to cover all the risks of every workplace but to assist
                MSMEs to put the risk estimation process into practice. The outcome will vary with the
                accuracy of input by the respondent, change in the local conditions among others.`,
            disclaimer2:`In this tool, the terms "Enterprise" and "Business" has been used interchangeably for the ease of understanding and translation.`,
            disclaimer3:`The tool does not collect any personal or confidential data of the respondents. Further, the
                data collected from the respondents will not be used for any commercial purpose.`,
            disclaimer4:`UNDRR and the other organisations involved in the development of the tool will not be liable
                for any false, inaccurate, inappropriate or incomplete information provided or stored in the
                tool or any other damages as a result of using the software.`,
            agreeTerm:'I accept terms and conditions',
            iAgree:'I accept',
            toolName:'Questionnaire for the Quick Risk Estimation (QRE) Tool',
            letsStart:`Let's start`,
            halfWay:'Half way through',
            bprofile:'Business Profile',
            understandingTheOrg:'Understanding the Organisation',
            HR:'Human Resource ',
            TI:'Technology and Innovation ',
            OP:'Operations',
            FIN:'Finance',
            GP:'Government Policy',
            MKT:'Market',
            EXH:'Exposure to Other Hazards',
            BCPDetails:'According to ISO 22301, business continuity plan is defined as “documented procedures that guide organizations to respond, recover, resume, and restore to a pre-defined level of operation following disruption.” (clause 3.5)',
            multipleSelect:'(Multiple answers can be selected)',
            select:'Select',
            selectErr:'Choose at least one answer',
            textInpErr:'Enter some data',
            currencyErr:'Select currency and enter some data',
            submit:'Submit',
            next:'Next',
            prev:'Prev'
        },
        resultDashboard:{
            retakesurvey:'Re-take the survey',
            riskScore:'Overall risk score',
            score:'Score',
            legends:'Legends',
            veryLow:'Very low',
            low:'Low',
            medium:'Medium',
            high:'High',
            veryHigh:'Very high',
            riskCategory:'The business risk in all categories',
            HR:'HR - Human Resource ',
            TI:'TI - Technology and Innovation ',
            OP:'OP - Operations',
            FIN:'FIN - Finance',
            GP:'GP - Government Policy',
            MKT:'M - Market',
            EXH:'EX - Exposure to Other Hazards',
            riskAroundYou:'Risk around you (This is based on your current location)',
            advice:'Advice',
            veryHighRisk:'Very High Risk',
            veryHighRiskData:`It is very likely that normal business operations would be completely disrupted. The current workplace measures taken are likely to be inadequate and need to be reviewed or new strategies created.`,
            veryLowRisk:'Very low Risk',
            veryLowRiskData:'It is very unlikely that the normal business operations would suffer any impact but close monitoring of the changing situation is needed.',
            lowRisk:'Low Risk',
            lowRiskData:'It is unlikely that the normal business operations would have any serious impact but a close monitoring of the changing situation is needed.',
            mediumRisk:'Medium Risk',
            mediumRiskData:'It is possible that the normal business operations would be impeded, but not fully disrupted and current workplace measures need to be improved or new measures might be taken up.',
            highRisk:'High Risk',
            highRiskData:'It is likely that the majority of the normal business operations would be disrupted and current workplace measures need to be improved and reviewed from time to time.',
            focusOn:'The risk is high in',
            focusOnsecond:'( The closer the score is to the redline, the higher the risk.The closer the score is to the greenline, the lower the risk).',
            statement1:'Some suggestive tools to help reduce business risk and increase resilience are listed below.',
            statement2a:'For continuity of the business and planning for recovery, you are encouraged to refer to the Business Continuity and Recovery Planning Toolkit',
            statement2b:'www.undrr.org/bcp-ap',
            statement2c:'',
            statement2d:'',
            statement3a:'An online e-learning orientation course has been developed to assist businesses to use the COVID-19 Toolkit. It can be accessed at',
            statement3b:'https://courses.adpc.net/courses/course-v1:UNDRR+COVID19SBCR+2020/about',
            feedbackForm:'Feedback Form',
            fullName:'Full Name',
            fullNamePlaceholder:"Enter Your Name",
            fullNameErr:'Enter Full Name',
            phoneNumberPlaceholder:"Enter Phone Number",
            phoneNumber: 'Phone Number',
            emailPlaceholder:"Enter Your Email ",
            email:'Email Address',
            emailErr:'Enter valid Email Address',
            feedbackmsg:'Message',
            feedbackmsgPlaceholder:'Your message here...',
            feedbackmsgErr:'Enter Feedback',
            adviceforYou:'Advice for you',
            highScorePossible:'Highest score possible',
            lowScorePossible:'Lowest score possible',
            lastevaluatedScore:'Last evaluated score',
            yourScore:'Your score',
        }
    },
    'sp': {
        common: {
            toolName: `herramienta para el cálculo rápido del riesgo (QRE)  `,
            forEnterprises: 'para micro, pequeñas y medianas empresas (MPYMES) ',
            audioAssistance: 'Asistencia de audio para encuestados con discapacidad visual',
            chooseLanguage: 'Seleccione el idioma',
            logout: 'Cerrar sesión'
        },
        dashboard: {
            toolInfo: `El propósito de esta herramienta es ayudar a las micro, pequeñas y medianas empresas, especialmente dentro del sector informal, a establecer una comprensión básica sobre su nivel de riesgo. Se espera que sea una de las tantas herramientas dirigidas a ayudar a los propietarios de empresas y negocios a comprender sus niveles de riesgo y aumentar su resiliencia.`,
            aboutTool: 'Información sobre la herramienta',
            takeMeSurvey: 'Ir a la herramienta',
            inParnership: 'En alianza con:'
        },
        aboutTool: {
            header: 'Herramienta de Estimación Rápida de Riesgos para Micro, Pequeñas y Medianas Empresas (MIPYMES)',
            introduction: 'Introducción',
            introLine1: `La pandemia de COVID-19 ha originado graves pérdidas económicas, por lo que se necesita un período sostenido de intervenciones, tanto de los gobiernos como del sector privado, para responder a la crisis y poder recuperarse. Por un lado, las micro, pequeñas y medianas empresas (MPYMES) corren un alto riesgo de resultar severamente afectadas por los efectos generales de la epidemia y, por otra parte, estas empresas son las que están encabezando la lucha por la supervivencia, no solo de sus propios negocios, sino también de los medios de vida en general, así como del bienestar de sus comunidades.`,
            introLine2: `Entre la serie de retos que enfrentan las MPYMES, el más destacado es la falta de acceso al crédito. Por ejemplo, en el sur asiático, el 33% de estas empresas carece de acceso a préstamos y a una línea de crédito. Por lo tanto, son más vulnerables a los choques, en comparación con las empresas más grandes. En el caso de choques más profundos, tal como el COVID-19, las MPYMES enfrentan un riesgo existencial debido a sus recursos más limitados, tanto financieros como no financieros.`,
            introLine3: `La mayoría de las MPYMES no tienen suficiente conocimiento especializado a nivel interno, ni acceso en sus áreas para ayudarles a elaborar planes de continuidad empresarial y de recuperación. Para apoyar a las MPYMES a que puedan alinear su razonamiento hacia la protección de sus negocios contra diversos riesgos, la herramienta propuesta para un cálculo rápido del riesgo busca lograr lo siguiente:`,
            scopeofTool: 'Alcance de la herramienta',
            scopeLine1: `Apoyar a los propietarios de empresas para identificar riesgos internos y externos de sus negocios con respecto al COVID-19`,
            scopeLine2: `Ofrecer una evaluación general de los riesgos de las empresas en un plano individual (MPYMES) con base en el posible impacto (directo) y la probabilidad (posibles efectos indirectos y más amplios) del COVID-19.`,
            scopeLine3: `Fortalecer y aumentar el grado de sensibilización y comunicación entre los propietarios de empresas y negocios. `,
            limitationofTool: 'Limitaciones de la herramienta ',
            limitationLine1: `La herramienta no realiza una evaluación exhaustiva de los riesgos de los negocios de las MPYMES. En lugar de ello, esta herramienta busca ofrecer una evaluación rápida general sobre la situación actual en la que funciona un negocio. Los propietarios de empresas pueden usar esta herramienta para evaluar los cambios temporales en las condiciones y la forma en que esto repercute en los riesgos empresariales generales. Con base en la evaluación general, se puede utilizar el conjunto de herramientas para la planificación de la continuidad y la recuperación de las pequeñas empresas ante el COVID 19, de UNDRR (Small Business Continuity and Recovery Planning Toolkit), con el propósito de analizar acciones específicas para la gestión del riesgo. `,
            targetGroup: 'Grupo meta',
            targetGroupLine1: `Propietarios de negocios que pertenecen a las micro, pequeñas y medianas empresas (MPYMES).`,
            platform: 'Plataforma',
            platformLine1: `Teléfono celular, tableta o computadora.`,
            framework: 'Marco e indicadores',
            frameworkLine1: `En la pandemia del COVID-19, una empresa experimentaría dos riesgos muy amplios. Los riesgos debido a factores internos y los que obedecen a factores externos, según se muestran en el gráfico 1. Hay cuatro factores integrales en un negocio o empresa, a saber: a) recursos humanos, b) operaciones, c) finanzas y d) tecnología e innovación. Las empresas gestionan estos factores y los cambios necesarios que deben efectuarse, con base en las variaciones en el escenario existente. En el caso de los riesgos externos, hay tres factores que inciden en el resultado de un negocio, a saber: a) políticas gubernamentales, b) cambios en el mercado y c) otras amenazas naturales. Por lo general, los riesgos externos están fuera del control de una empresa individual para que los pueda gestionar. `,
            frameworkLine2:'Figure 1. A Conceptual Framework for Business Risk in COVID-19 Pandemic',
            tableHead1:'Recursos humanos',
            tableHead2:'Operaciones',
            tableHead3:'Finanzas',
            tableHead4:'Tecnología e innovación',
            row11:'Disponibilidad de los empleados',
            row12:'Cadena de suministro',
            row13:'Pasivos existentes',
            row14:'Ciberseguridad ',
            row21:'Comportamiento de los empleados',
            row22:'Servicios públicos',
            row23:'Capacidad financiera',
            row24:'Innovación',
            row31:'Tipo de empleo',
            row32:'Seguridad en el lugar de trabajo',
            row33:'Seguros',
            row34:'Acceso a mercados digitales',
            row41:'Efectos en los empleados',
            row42:'Activos físicos',
            references:'Referencias bibliográficas',
            references1:`COSO (Comité de Organizaciones Patrocinadoras de la Comisión Treadway), (2004). Enterprise Risk Management - Integrated Framework,Vol. 2.`,
            references2:`Ekwere N. (2016) Framework of Effective Risk Management in Small and Medium Enterprises (SMEs): A Literature Review; Vol 20. 1° de noviembre de 2016.`,    
            references3:`ILO (2020) The six-step COVID-19 business continuity plan for SMEs.`,    
            references4:`ISO 22301 Societal security: Business continuity management systems.`,    
            references5a:`KPMG (2020) Covid-19 Response Assessment Tool. Consultado en línea`,    
            references5b:`https://aspac.kpmgcovidresponse.com/`,    
            references5c:`el 18 de mayo de 2020 `,    
            references6:`McGuinness W (2006). Managing the business risk of a pandemic: Lessons from the past and a checklist for the future, Discussion paper, Sustainable Future.`,    
            references7:`Organización Mundial de la Salud (2018). Lista de verificación para gestionar los riesgos y los efectos de una gripe pandémica.`,    
            references8:`UAE (2020) Business Continuity Readiness Guidelines for UAE Organizations in the in the event of the Novel Coronavirus (COVID- 1 9), National Emergency Crisis and Disasters Management Authority (NECMA). Marzo de 2020.`,    
            references9:`UNDRR (2013) Informe de Evaluación Global sobre Reducción del Riesgo de Desastres (GAR).`,    
            references10a:`11.	UNESCAP (sin fecha) SMEs in Asia And the Pacific. Consultado en línea en:`,    
            references10b:`https://www.unescap.org/sites/default/files/7%20-%201.%20SMEs%20IN%20ASIA`,    
            references10c:`%20AND%20THE%20PACIFIC.pdf`,    
            references11:`UNDRR (2020) COVID-19 Small Business Continuity and Recovery Planning Toolkit.`,    
        },
        login: {
            login: `Iniciar sesión`,
            errormsg: 'Correo electrónico o contraseña equivocada',
            erroremail: 'Introduzca una dirección de correo electrónico válida',
            password: 'Introduzca la contraseña',
            invalidpassword: 'La contraseña es inválida. Debe tener al menos 8 caracteres.',
            forgotpassword: 'Olvidé la contraseña?',
            loginWith:'Iniciar sesión con ',
            signup:'Inscríbase',
            dontaccount:`¿No tiene una cuenta?`,
            guest:'Inicie sesión como invitado(a)',
            email:'Introduzca la dirección de correo electrónico',
            enterPassword:'Introduzca la contraseña',
            guestLogin:'Inicie sesión como invitado(a)',
            guestLoginData:'Si continúa como invitado, sus resultados no se guardarán y no podrá realizar un seguimiento de su riesgo en el futuro. Quieres proceder?',
            cancel:'Cancelar',
            Proceed:'Proceder',
        },
        forgotPassword:{
            forgotPassword:'Olvidé la contraseña',
            error:'Ningún usuario con esta dirección de correo electrónico.',
            email:'Introduzca la dirección de correo electrónico',
            invalidemail:'Introduzca una dirección de correo electrónico válida',
            submit:'Enviar'
        },
        changePassword:{
            changePassword:'Cambia la contraseña',
            error:'Ningún usuario con esta dirección de correo electrónico.',
            email:'Introduzca la dirección de correo electrónico',
            invalidemail:'Introduzca una dirección de correo electrónico válida',
            submit:'Enviar'
        },
        register:{
            register:'Registrarse',
            error:'No se ha registrado el usuario, por favor, inténtelo de nuevo.',
            firstName:'Ingrese el nombre',
            firstNameLable:'Nombre ',
            firstNameErr:'Por favor, introduzca su nombre',
            lastNameLable:'Apellido',
            lastName:'Introduzca su apellido',
            lastNameErr:'Por favor, introduzca su apellido',
            emailLable:'Correo electrónico',
            email:'Introduzca la dirección de correo electrónico',
            emailErr:'Por favor, introduzca una dirección de correo electrónico válida',
            passwordLable:'Contraseña',
            password:'Introduzca la contraseña',
            passwordErr:'Por favor, introduzca una contraseña válida',
            invalidpasswordErr:'La contraseña es inválida. Debe tener al menos 8 caracteres.',
            signup:'Registrarse',
            haveaccount:'¿Ya tienes una cuenta?',
            signin:'Registrarse',
        },
        questionnaire:{
            genaralStatement:'Enunciados generales:',
            genaralStatement1:`Esta encuesta está diseñada para ayudarle a realizar un cálculo rápido del riesgo de su empresa o negocio en el contexto de la pandemia del COVID-19.`,
            genaralStatement2:`Para permitir una visualización precisa de su mapa de riesgo, por favor permita que se comparta su ubicación. En el tablero, se muestra el “riesgo a su alrededor” (“Risk around you”), con base a su ubicación actual.`,
            genaralStatement3:`Le tomará unos 10 a 15 minutos finalizar la encuesta.`,
            genaralStatement4a:`Para consultar terminología, por favor visite:`,
            genaralStatement4b:`https://www.undrr.org/terminology`,
            disclaimer:'Exención de responsabilidad:',
            disclaimer1:`Por favor tenga presente que el resultado de la herramienta es un riesgo indicativo que surge debido a la pandemia actual. La herramienta no busca abarcar todos los riesgos en cada lugar de trabajo, sino que su intención es prestar asistencia a las MPYMES a poner en práctica el proceso de cálculo del riesgo. El resultado variará según la precisión o la exactitud de los insumos del encuestado, así como los cambios experimentados en las condiciones locales, entre otras cosas.`,
            disclaimer2:`En esta herramienta, “empresa” y “negocio” se utilizan indistintamente, para facilitar su comprensión y traducción. `,
            disclaimer3:`La herramienta no capta ningún dato confidencial o personal de los encuestados. Además, no se utilizarán los datos recopilados de los encuestados para ningún propósito comercial.`,
            disclaimer4:`UNDRR y otras organizaciones que participaron en el desarrollo de la herramienta no son responsables de ninguna información falsa, inexacta, inadecuada o incompleta suministrada o guardada en la herramienta, ni de cualquier otro daño debido al uso del software.`,
            agreeTerm:'Acepto los términos y condiciones',
            iAgree:'Acepto',
            toolName:'Cuestionario sobre la herramienta para el cálculo rápido del riesgo (QRE)',
            letsStart:`Empecemos`,
            halfWay:' A medio camino',
            bprofile:'Perfil de trabajo',
            understandingTheOrg:'Entendiendo la Organización',
            HR:'Recursos humanos ',
            TI:'Tecnología e innovación ',
            OP:'Operaciones ',
            FIN:'Finanzas ',
            GP:'Políticas gubernamentales ',
            MKT:'Mercado ',
            EXH:'Exposición a otras amenazas ',
            BCPDetails:'De acuerdo con ISO 22301, el plan de continuidad del negocio se define como "procedimientos documentados que guían a las organizaciones a responder, recuperar, reanudar y restaurar a un nivel predefinido de operación después de una interrupción". (cláusula 3.5)',
            multipleSelect:'(Se pueden escoger varias respuestas)',
            select:'Seleccione',
            selectErr:'Elige al menos una respuesta',
            textInpErr:'Ingrese algunos datos',
            currencyErr:'Seleccione la moneda e ingrese algunos datos.',
            submit:'Enviar',
            next:'Siguiente',
            prev:'Anterior'
        },
        resultDashboard:{
            retakesurvey:'Volver a hacer la encuesta',
            riskScore:'Puntaje general del riesgo',
            score:'Puntuación',
            legends:'Leyendas',
            veryLow:'Muy bajo',
            low:'Bajo',
            medium:'Medio',
            high:'Alto',
            veryHigh:'Muy alto',
            riskCategory:'Los riesgos empresariales en todas las categorías ',
            HR:'RH - recursos humanos',
            TI:'TI: tecnología e innovación',
            OP:'OP - operaciones',
            FIN:'FIN - finanzas',
            GP:'PG - política gubernamental',
            MKT:'M - mercado',
            EXH:'EX - exposición a otras amenazas',
            riskAroundYou:'El riesgo a su alrededor (con base en su ubicación actual)',
            advice:'Orientación',
            veryHighRisk:'Grado muy alto de riesgo',
            veryHighRiskData:`Es muy probable que las operaciones o actividades empresariales normales se interrumpan por completo. Es posible que las medidas actuales tomadas en el lugar de trabajo sean inadecuadas y existe la necesidad de revisarlas o de establecer nuevas estrategias.`,
            veryLowRisk:'Grado muy bajo de riesgo',
            veryLowRiskData:'Es muy poco probable que las operaciones o actividades empresariales normales experimenten algún impacto, pero es necesario seguir muy de cerca la evolución de la situación. ',
            lowRisk:'Grado bajo de riesgo',
            lowRiskData:'Es poco probable que surjan efectos graves en las operaciones o actividades empresariales normales, pero es necesario seguir muy de cerca la evolución de la situación.',
            mediumRisk:'Grado medio de riesgo',
            mediumRiskData:'Es posible que se dificulten las operaciones o actividades empresariales normales, pero estas no se interrumpirían por completo y las medidas actuales tomadas en el lugar de trabajo se deben mejorar y podría ser necesario reanudarlas.',
            highRisk:'Grado alto de riesgo',
            highRiskData:'Es probable que la mayoría de las operaciones o actividades empresariales normales se interrumpan y las medidas actuales tomadas en el lugar de trabajo se deben mejorar y revisar ocasionalmente. ',
            focusOn:'El riesgo es alto en ',
            focusOnsecond:'(Entre más cerca se situé el puntaje a la línea roja, más alto será el riesgo. Entre más cerca se sitúe el puntaje a la línea verde, más bajo será el riesgo). ',
            statement1:'A continuación se sugieren algunas herramientas para ayudarle a reducir los riesgos empresariales y aumentar la resiliencia. ',
            statement2a:'Para la continuidad empresarial y la planificación de la recuperación de los negocios, se sugiere que se remita al conjunto de herramientas para la planificación de la continuidad y la recuperación de las pequeñas empresas',
            statement2b:'https://www.undrr.org/es/publication/es-su-negocio-resiliente-frente-al-covid-19',
            statement2c:'https://www.undrr.org/es/publication/5-consejos-adicionales-para-ayudar-su-negocio-si-ya-esta-afectado',
            statement2d:'https://www.undrr.org/es/publication/es-su-negocio-resiliente-frente-al-covid-19-15-consejos-infografia',
            statement3a:'Se ha desarrollado un curso de orientación y aprendizaje en línea, con el fin de prestar asistencia a las empresas para que usen el conjunto de herramientas para el COVID-19, el cual está disponible en',
            statement3b:'https://courses.adpc.net/courses/course-v1:UNDRR+COVID19SBCR+2020/about',
            feedbackForm:'Formulario para comentarios',
            fullName:'Nombre completo',
            fullNamePlaceholder:"Introduzca su nombre completo",
            fullNameErr:'Ingrese su nombre completo',
            phoneNumberPlaceholder:"Introduzca el número de teléfono",
            phoneNumber: 'Número telefónico',
            emailPlaceholder:"Ingrese su correo electrónico",
            email:'Dirección de correo electrónico',
            emailErr:'Introduzca una dirección de correo electrónico válida',
            feedbackmsg:'Mensaje',
            feedbackmsgPlaceholder:'Su mensaje aquí...',
            feedbackmsgErr:'Ingresar comentarios',
            adviceforYou:'Un consejo para usted',
            highScorePossible:'Puntaje más alto posible',
            lowScorePossible:'Puntaje más bajo posible',
            lastevaluatedScore:'Última puntuación evaluada',
            yourScore:'Su puntaje',
        }
    }
}
export default i18n;